<template>
  <div>
    <modal-delete-meetingtype
      v-if="showDeleteMeetingtype"
      :showModal="showDeleteMeetingtype"
      :onClickCancel="hideModal"
      :meetingtype="selectedMeetingtype"
    />

    <modal-edit-meetingtype
      v-if="showEditMeetingtype"
      :showModal="showEditMeetingtype"
      :onClickCancel="hideModal"
      :meetingtype="selectedMeetingtype"
    />

    <ui-section-header>
      <template v-slot:title>Meeting types</template>
    </ui-section-header>

    <div class="columns">
      <div
        v-for="(meetingtype, index) in location.Meetingtypes"
        :key="index"
        class="column"
      >
        <div class="level">
          <div class="level-left">
            <div class="subtitle">
              {{ meetingtype.MeetingtypeId | getMeetingtypeName }}
            </div>
          </div>
          <div class="level-right">
            <a @click="setShowEditMeetingtype(meetingtype)" class="has-icon">
              <span class="icon">
                <font-awesome-icon :icon="['fa', 'edit']" />
              </span>
              <span>Edit</span>
            </a>

            <a
              @click="setShowDeleteMeetingtype(meetingtype)"
              class="has-margin-left has-text-danger"
            >
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'trash-alt']" />
              </span>
            </a>
          </div>
        </div>

        <table class="table is-fullwidth is-striped is-hoverable">
          <tbody>
            <tr>
              <td>Allow prices</td>
              <td class="has-text-right">
                <span class="icon">
                  <font-awesome-icon
                    v-if="meetingtype.AllowPrices"
                    :icon="['fas', 'check']"
                    class="has-text-success"
                  />
                  <font-awesome-icon
                    v-if="!meetingtype.AllowPrices"
                    :icon="['fas', 'times']"
                    class="has-text-danger"
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td>Check capacity</td>
              <td class="has-text-right">
                <span class="icon">
                  <font-awesome-icon
                    v-if="meetingtype.CheckCapacity"
                    :icon="['fas', 'check']"
                    class="has-text-success"
                  />
                  <font-awesome-icon
                    v-if="!meetingtype.CheckCapacity"
                    :icon="['fas', 'times']"
                    class="has-text-danger"
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td>Must be confirmed</td>
              <td class="has-text-right">
                <span class="icon">
                  <font-awesome-icon
                    v-if="meetingtype.MustBeConfirmed"
                    :icon="['fas', 'check']"
                    class="has-text-success"
                  />
                  <font-awesome-icon
                    v-if="!meetingtype.MustBeConfirmed"
                    :icon="['fas', 'times']"
                    class="has-text-danger"
                  />
                </span>
              </td>
            </tr>

            <tr>
              <td>Book max. days out</td>
              <td class="has-text-right">
                <span class="icon">
                  <font-awesome-icon
                    v-if="meetingtype.MaxDaysOut === -1"
                    :icon="['fas', 'minus']"
                  />
                </span>
                <span v-if="meetingtype.MaxDaysOut > -1"
                  >{{ meetingtype.MaxDaysOut }} days</span
                >
              </td>
            </tr>

            <tr>
              <td>Minutes between reservations</td>
              <td class="has-text-right">
                <span
                  >{{ meetingtype.MinutesBetweenReservations }} minutes</span
                >
              </td>
            </tr>
          </tbody>
        </table>

        <table class="table is-fullwidth is-striped is-hoverable">
          <thead>
            <tr>
              <th>Day</th>
              <th class="has-text-right">Lead days</th>
              <th class="has-text-right">Bookable</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(day, index) in filteredDays(meetingtype.MeetingtypeId)"
              :key="index"
            >
              <td>{{ day.Day | getDayName }}</td>
              <td width="125" class="has-text-right">
                <span>{{ day.DaysToStart }} </span>
                <span v-if="day.DaysToStart === 1">day</span>
                <span v-if="day.DaysToStart !== 1">day(s)</span>
              </td>
              <td class="has-text-right">
                <span
                  class="is-size-7 has-text-weight-light is-italic has-text-grey"
                  >First
                  {{
                    calculateNextDay(day.Day, day.DaysToStart) | getDayName
                  }}</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const ModalDeleteLocationMeetingtype = () =>
  import('@/components/Locations/Settings/ModalDeleteLocationMeetingtype')
const ModalEditLocationMeetingtype = () =>
  import('@/components/Locations/Settings/ModalEditLocationMeetingtype')

export default {
  components: {
    'modal-delete-meetingtype': ModalDeleteLocationMeetingtype,
    'modal-edit-meetingtype': ModalEditLocationMeetingtype,
  },

  props: {},

  data() {
    return {
      showDeleteMeetingtype: false,
      showEditMeetingtype: false,
      selectedMeetingtype: null,
    }
  },

  computed: {
    ...mapState('locationStore', ['location']),

    meetingtypeIds() {
      return this.location.Meetingtypes.map((m) => m.MeetingtypeId)
    },
  },

  created() {},

  methods: {
    calculateNextDay(day, daysToStart) {
      let nextDay = day + daysToStart

      if (nextDay > 7) {
        nextDay = nextDay - 7
      }

      return nextDay
    },

    filteredDays(meetingtypeId) {
      let index = this.location.Meetingtypes.findIndex(
        (m) => m.MeetingtypeId === meetingtypeId
      )
      if (index > -1) {
        return this.location.Meetingtypes[index].Days
      }

      return []
    },

    setShowDeleteMeetingtype(meetingtype) {
      this.selectedMeetingtype = meetingtype
      this.showDeleteMeetingtype = true
    },

    setShowEditMeetingtype(meetingtype) {
      this.selectedMeetingtype = meetingtype
      this.showEditMeetingtype = true
    },

    hideModal() {
      this.selectedMeetingtype = null
      this.showDeleteMeetingtype = false
      this.showEditMeetingtype = false
    },
  },
}
</script>

<style></style>
